.chevron-icon {
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.hide {
  display: none;
}
