$fontColor: #4b4a4a;

.image-grid-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    width: 1170px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      width: 356px;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 47.5%;
    margin-left: auto;

    font-family: 'Raleway', sans-serif;
    color: $fontColor;
    line-height: 40px;
    font-size: 20px;
    text-align: left;
    padding: 3rem 0rem;

    @media (max-width: 1200px) {
      padding: 1rem 0rem;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .name {
    font-weight: 700;
    font-size: 1.25rem;
    &:hover {
      cursor: pointer;
      color: #01dcd1;
    }
  }

  .description {
    font-weight: 400;
    font-size: 1.25rem;
  }

  a {
    color: $fontColor;
    text-decoration: none;
  }
}
