.tile {
  position: relative;
  width: 354px;
  height: 260px;
  overflow: hidden;
  text-decoration: none;
  border: 2px solid transparent;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  border: 1px solid transparent;

  &.text-1 {
    text-align: left;
    font-size: 1.4rem;
    line-height: 44px;
    font-weight: 400;
    color: #ca5541;
    transition: color 0.5s;

    &.hovered {
      color: #01dcd1;
    }

    &.get-in-touch {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 60px;
      font-weight: 600;
      line-height: 70px;
    }
    &:hover {
      color: #01dcd1;
    }
  }

  &.text-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.4rem;
    line-height: 45px;
    font-weight: 400;
    color: #ca5541;
    transition: color 0.5s;

    &.hovered {
      color: #01dcd1;
    }
    &:hover {
      color: #01dcd1;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.5s;

    &.hovered {
      opacity: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #01dcd1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.5s;

    &.hovered {
      opacity: 1;
    }
  }

  &__text {
    line-height: 2rem;
  }

  &__text,
  &__bot-text {
    text-align: center;
    color: white;
    font-size: 1.45rem;
  }

  &__bot-text {
    padding-top: 0.5em;
    font-size: 1rem;
  }
}
