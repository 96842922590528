@font-face {
  font-family: 'Lora';
  src: url('../../public/fonts/Lora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../public/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Raleway', 'Lora', sans-serif;
}
