.middle-section-wrapper {
  width: 100vw;
  background: #3e5341;
  font-family: 'Raleway', sans-serif;
  color: white;
  line-height: 40px;
  font-size: 1rem;

  &__text-wrapper {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding: 6rem 0rem;
    display: flex;
    flex-direction: column;

    .first {
      @media (max-width: 768px) {
        padding-top: 2rem;
      }
    }

    @media (max-width: 1200px) {
      width: 760px;
    }
    @media (max-width: 768px) {
      padding-top: 2rem;
      width: 354px;
    }
  }

  .categories-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
    .category {
      // font-style: italic;

      &.selected {
        font-weight: 700;

        @media (max-width: 768px) {
          color: #01dcd1;
        }
      }

      &:hover {
        cursor: pointer;
        color: #01dcd1;
      }
    }
    .pipe {
      padding: 0 1rem;
      font-size: 1rem;
      font-weight: 400 !important;
    }
  }

  .categories-wrapper,
  .words-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 50px;
    @media (max-width: 768px) {
      line-height: 45px;
    }
  }

  .words-wrapper {
    .word {
      padding-right: 2rem;
      transition: font-weight 0.2s ease, text-shadow 0.2s ease;
      // letter-spacing: 2px;
      font-weight: 200;
      font-size: 1.25rem;

      @media (max-width: 768px) {
        font-size: 1rem;
        padding-right: 0.75rem;
      }

      &.selected {
        font-weight: bold;
      }

      &.not-selected {
        opacity: 0.7;
      }
    }
  }
}
