$harmony: #688563;

.project-page {
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Raleway', sans-serif;
    line-height: 32px;
    color: #4b4a4a;
    padding-bottom: 5rem;
    width: 1170px;

    @media (max-width: 1200px) {
      width: 760px;
    }

    @media (max-width: 768px) {
      max-width: 356px;
    }

    .title-type-wrapper {
      display: flex;
      flex-direction: row;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .bar {
        font-size: 1.65rem;
        padding: 0em 1em;
      }
    }

    .title {
      font-size: 28px;
      padding-bottom: 0.5rem;
      font-weight: 300;
      color: $harmony;
      display: inline;

      &.lh {
        font-size: 28px;
      }
    }

    .description {
      white-space: pre-line;
      width: 75%;
      font-weight: 400;
      padding-top: 1rem;

      @media (max-width: 1200px) {
        width: 90%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .description_secondary {
      padding-top: 2rem;
      width: 75%;
      font-weight: 400;

      @media (max-width: 1200px) {
        width: 90%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .description_secondary-main,
    .description_third-main {
      font-weight: 400;
      padding-right: 1em;
      color: $harmony;
      @media (max-width: 767px) {
        padding-right: 0em;
      }
    }

    .description_third-description {
      white-space: pre-line;
    }

    .secondary-wrapper {
      display: flex;
      flex-direction: column;
      padding-top: 1.5em;
      width: 75%;

      @media (max-width: 1200px) {
        width: 90%;
      }

      .description_secondary-description {
        font-weight: 400;
      }
    }
  }
}

.secondary-video-description {
  font-family: 'Raleway', sans-serif;
  line-height: 32px;
  color: #979797;
  font-weight: 400;
  padding-bottom: 2em;
}

.angled-line-project {
  padding-top: 5rem;
}

.description_secondary-main,
.description_third-main,
.description_secondary-description,
.description_third-main,
.description_third-description,
.description,
.description_secondary-main,
.description_third-main {
  font-size: 1.25rem;
}

.lh {
  font-size: 28px;
}

@media (max-width: 768px) {
  .lh-top {
    padding-bottom: 0.2rem;
  }
}
