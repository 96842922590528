$orange: #ca5541;

.mobile-menu {
  position: fixed;
  color: white;
  font-family: 'Raleway', sans-serif;
  width: 100%;
  z-index: 1200;

  .hamburger-react {
    position: fixed;
    top: 20px;
    z-index: 1201;
    right: -80vw;

    @media (min-width: 600px) and (max-width: 768px) {
      left: calc(50% - (-1 * 171px) + (100vw - 110vw) / 2);
    }

    @media (max-width: 600px) {
      left: calc(50% - (-1 * 155px) + (100vw - 110vw) / 2);
    }
  }

  .overlay {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    // width: 150%;
    height: 100%;
    background: #3e5341;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out;
    z-index: 1200;
    padding-top: 3rem;
    padding-left: 2rem;

    .hamburger__main-link {
      color: white;
      padding-top: 1rem;
      line-height: 30px;
      font-size: 30px;
      font-weight: 300;
      text-align: left;
      text-decoration: none;
      opacity: 0.75;

      &.hallo {
        padding-bottom: 2rem;
      }

      &.active {
        opacity: 1;
        font-weight: 600;
      }
    }

    .hamburger__sub-links {
      display: flex;
      flex-direction: column;
      color: white;
      padding-top: 2rem;
      padding-left: 2rem;
      height: 67%;
      overflow: auto;

      .hamburger__sub-link {
        color: white;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        font-weight: 300;
        // white-space: nowrap;
        // white-space: pre-wrap;
        // word-break: break-word;
        text-align: left;
        text-decoration: none;
        padding-bottom: 2rem;
        opacity: 0.75;

        &.active {
          // color: #01dcd1;
          font-weight: 700;
          opacity: 1;
        }
      }
    }

    &.open {
      transform: translateX(0);
    }
  }
  .type-span {
    display: flex;
    flex-direction: row;
    width: 80vw;
  }

  .bar {
    padding: 0px 5px;
  }
}
