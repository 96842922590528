$mainGreen: #688563;
$background: #3e5341;
$fontColor: #979797;

.footer {
  position: relative;
  font-family: 'Raleway', sans-serif;
  color: white;

  &__wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 5rem 0rem 3rem 0rem;
    background-color: $background;
    @media (max-width: 768px) {
      padding-top: 3rem;
      padding-bottom: 1rem;
    }
  }

  .footer__text_wrapper {
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    justify-content: space-between;
    width: 1170px;

    @media (max-width: 1200px) {
      width: 760px;
    }
    @media (max-width: 768px) {
      width: 354px;
    }

    .text {
      .text-footer-link {
        font-weight: 400;
        letter-spacing: 0.5px;
        text-decoration: none;
        color: white;
        padding-right: 15rem;

        @media (max-width: 768px) {
          padding-right: 0rem;
        }
      }
      a {
        &:hover {
          color: #01dcd1;
        }
      }
    }

    .contact-wrapper {
      display: flex;
      flex-direction: column;
      .text:first-of-type {
        padding-bottom: 2rem;
      }

      .text {
        .text-footer-link {
          font-weight: 400;
          letter-spacing: 0.5px;
          text-decoration: none;
          color: white;
        }
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;

      > * {
        padding-bottom: 1em;
      }
    }

    .footer__column:first-of-type {
      font-weight: 400;
      @media (max-width: 768px) {
        width: auto;
        height: 10vh;
        margin-bottom: 3em;
      }
    }

    .footer__column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: self-end;

      @media (max-width: 768px) {
        align-items: normal;
      }

      .impressum {
        color: white;
        font-size: 0.85rem;
        text-decoration: none;

        &:hover {
          color: #01dcd1;
        }
      }

      &:first-child .text-link {
        text-decoration: underline $fontColor;
        color: $fontColor;
      }

      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
        flex-direction: column;
      }
    }
  }
}
