$fontColor: #979797;

.image-grid-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image-wrapper {
    width: 100vw;
    max-width: 1170px;
    padding: 2rem 3rem;

    @media (max-width: 1200px) {
      max-width: 760px;
      padding: 2rem 0rem;
    }

    @media (max-width: 768px) {
      max-width: 356px;
      padding: 2rem 0rem;
    }

    @media (max-width: 767px) {
      padding: 0rem 3rem 10px 3rem;
    }
  }

  &__text-wrapper {
    font-family: 'Raleway', sans-serif;
    color: $fontColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 32px;
    justify-content: center;
    text-align: center;
    padding: 3rem 0rem 3rem 0rem;
  }

  .name {
    font-weight: bold;
    padding-bottom: 1rem;
  }

  .description {
    font-weight: 100;
  }
}
