.main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em 2em 4em 2em;
  background-color: #d9d8d8;
  padding-bottom: 4rem;
  flex-direction: column;

  &.hallo {
    padding-top: 0em;
    padding-bottom: 0em;
  }

  &.impressum {
    background-color: #688563;
  }

  @media (max-width: 768px) {
    padding: 2rem 2rem 4rem 2rem;
  }

  &.project {
    background-color: white;
  }
}
