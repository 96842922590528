.grid {
  display: grid;
  gap: 3rem;

  // 3 columns for large screens
  grid-template-columns: repeat(3, 1fr);

  // 2 columns for medium screens
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  // 1 column for small screens
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
