.chevron-wrapper {
  position: fixed;
  background: transparent;
  top: 50%;
  width: 100%;
  z-index: 30;
}

.left {
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: 768px) {
    // left: -25px;
    display: none;
  }
}

.right {
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 768px) {
    right: -25px;
  }
}

.up {
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;

  &.portfolio {
    background-color: #d9d8d8;
  }

  &.impressum {
    background-color: #688563;
  }

  &:hover {
    cursor: pointer;
  }
  // top: 275px;

  @media (max-width: 768px) {
    // margin-left: 37%;
    // display: none;
  }
}

.icon {
  transition: 'stroke-width 0.3s ease';
}
