.impressum-wrapper {
  background-color: #688563;
  color: white;
  font-size: 1.25em;
  font-family: 'Raleway', sans-serif;
  line-height: 22px;
  font-size: 16px;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1200px) {
    max-width: 760px;
  }

  @media (max-width: 768px) {
    max-width: 356px;
  }

  .section-wrapper {
    padding-bottom: 4em;
    font-size: 1.25rem;
    line-height: 30px;

    &.npb {
      padding-bottom: 0em;
    }

    .section-header {
      padding-bottom: 2rem;
      font-weight: 200;
      font-size: 28px;
    }

    .section-text-section {
      padding-bottom: 1.5em;

      .bold {
        font-weight: 700;
      }
      .secondary-header {
        margin-bottom: -1em;
      }
    }
  }

  .section-header,
  .section-text-section {
    font-size: 1.25rem;
    line-height: 30px;
  }
}

ul {
  padding-left: 20px;
  margin-top: 0px;
}
