.video-list {
  max-width: 1170px;
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 760px;
  }

  @media (max-width: 768px) {
    width: 356px;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5em;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding-bottom: 1em;
    }
  }
}
