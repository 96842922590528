.image-row {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1220px) {
    padding: 2rem 0rem;
  }

  @media (max-width: 1200px) {
    max-width: 760px;
  }

  @media (max-width: 768px) {
    max-width: 356px;
  }
}
