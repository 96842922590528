$mainGreen: #688563;
$background: white;
$underline: #ca5541;

.navbar {
  background: white;
  height: 120px;
  display: flex;
  z-index: 1;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  position: fixed;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 90px;
  }

  &.active {
    background: $mainGreen;
    color: white;
  }

  .navbar-wrapper {
    width: 1170px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1200px) {
      width: 760px;
    }
    @media (max-width: 768px) {
      width: 354px;
    }
  }

  .first_name {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 200;
  }

  &__name {
    font-family: 'Lora', serif;
    font-weight: 700;
    font-style: normal;
    margin-top: auto;
    color: $mainGreen;
    font-size: 3.5rem;
    bottom: -84px !important;
    position: absolute;
    padding-top: 5rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      bottom: -66px !important;
    }

    &.active {
      color: white;
    }

    // @media (max-width: 768px) {
    //   bottom: -50px;
    //   left: calc(50% - 178px + (100vw - 110vw) / 2);
    // }

    // @media (min-width: 1201px) {
    //   left: calc(50% - 582px + (100vw - 110vw) / 2);
    // }

    // @media (min-width: 769px) and (max-width: 1201px) {
    //   left: calc(50% - 379px + (100vw - 110vw) / 2);
    // }
  }

  &__wrapper {
    position: absolute;
    bottom: -23px;
    right: 0px;

    // @media (min-width: 1201px) {
    //   left: calc(50% - (-1 * 400px) + (100vw - 110vw) / 2);
    // }

    // @media (max-width: 1201px) {
    //   left: calc(50% - (-1 * 200px) + (100vw - 110vw) / 2);
    // }
  }

  &__links {
    display: flex;
    flex-direction: row;
    font-family: 'Raleway', sans-serif;

    &.portfolio-page {
      color: white;
    }

    .hallo-link {
      margin-left: 5rem;
      text-align: right;
    }

    a {
      color: $mainGreen;
      text-decoration: none;
      text-align: center;
      font-size: 1.25rem;
      font-weight: 400;
      position: relative;
      display: inline-block;

      &.active {
        font-weight: 600;
      }

      &.portfolio-page {
        color: white;
      }

      &:hover {
        color: #01dcd1;
      }
    }
  }
}
